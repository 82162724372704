
export const CarouselItems = [
    {
        title: "Hidden Gems: Best role playing games to play",
        description: "Dragon Pet amongst Hidden Gems!",
        imgSrc: "/img/banners/dp-hidden-gems3.jpg",
        link: "https://gameskeys.net/hidden-gems-best-role-playing-games-to-play/",
        alt: ""
    },
    {
        title: "We are still working on it!",
        description: "Take a sneak peak how it looks now",
        imgSrc: "/img/banners/dp2-promo.jpg",
        link: "http://ntwins.pl/#/details/dragonpet2",
        alt: ""
    }
];
