import React from "react";
import {Container} from "react-bootstrap";
import "./Contact.css";

export const Contact = () => {
    return (
        <div id="contact">
            <div>
                Contact
            </div>
            <Container>
                ntwins.info@gmail.com
            </Container>
        </div>
    );
};